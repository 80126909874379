const dataLogo = {
    "v": "5.5.2",
    "fr": 25,
    "ip": 0,
    "op": 140,
    "w": 1320,
    "h": 400,
    "nm": "Komp 1",
    "ddd": 0,
    "assets": [],
    "layers": [{
        "ddd": 0,
        "ind": 1,
        "ty": 4,
        "nm": "Formebene 1",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [660, 197.875, 0], "ix": 2},
            "a": {"a": 0, "k": [0, 0, 0], "ix": 1},
            "s": {"a": 0, "k": [98.23, 100.231, 100], "ix": 6}
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[-10, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[44, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[-554, 68], [311, 68], [311, -54], [556.473, -54.249], [556.091, -182.952]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Pfad 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 3, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 4,
                "bm": 0,
                "nm": "Kontur 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [0, 0], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transformieren"
            }],
            "nm": "Form 1",
            "np": 3,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "tm",
            "s": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833], "y": [0.833]},
                    "o": {"x": [0.167], "y": [0.167]},
                    "t": 50,
                    "s": [0]
                }, {"t": 88, "s": [100]}],
                "ix": 1
            },
            "e": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833], "y": [0.833]},
                    "o": {"x": [0.167], "y": [0.167]},
                    "t": 5,
                    "s": [0]
                }, {"t": 55, "s": [100]}],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "m": 1,
            "ix": 2,
            "nm": "Pfade trimmen 1",
            "mn": "ADBE Vector Filter - Trim",
            "hd": false
        }],
        "ip": 0,
        "op": 140,
        "st": -20,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 2,
        "ty": 4,
        "nm": "z/fbr Konturen",
        "sr": 1,
        "ks": {
            "o": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833], "y": [0.833]},
                    "o": {"x": [0.167], "y": [0.167]},
                    "t": 85,
                    "s": [0]
                }, {"t": 134, "s": [100]}],
                "ix": 11
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {
                "a": 1,
                "k": [{
                    "i": {"x": 0.833, "y": 0.833},
                    "o": {"x": 0.167, "y": 0.167},
                    "t": 85,
                    "s": [768.5, 237, 0],
                    "to": [3.333, 0, 0],
                    "ti": [-3.333, 0, 0]
                }, {"t": 100, "s": [788.5, 237, 0]}],
                "ix": 2
            },
            "a": {"a": 0, "k": [31, 37.5, 0], "ix": 1},
            "s": {"a": 0, "k": [250, 250, 100], "ix": 6}
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[30.57, 36.824], [30.57, 26.263], [-15.425, 26.263], [-15.425, 25.984], [30.432, -28.208], [30.432, -36.824], [-27.93, -36.824], [-27.93, -26.401], [15.703, -26.401], [15.703, -26.124], [-30.57, 27.93], [-30.57, 36.824]],
                        "c": true
                    },
                    "ix": 2
                },
                "nm": "Pfad 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fläche 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [30.82, 37.074], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transformieren"
            }],
            "nm": "Gruppe 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }],
        "ip": 85,
        "op": 140,
        "st": 85,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 3,
        "ty": 4,
        "nm": "n/fbr Konturen",
        "sr": 1,
        "ks": {
            "o": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833], "y": [0.833]},
                    "o": {"x": [0.167], "y": [0.167]},
                    "t": 76,
                    "s": [0]
                }, {"t": 125, "s": [100]}],
                "ix": 11
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {
                "a": 1,
                "k": [{
                    "i": {"x": 0.833, "y": 0.833},
                    "o": {"x": 0.167, "y": 0.167},
                    "t": 70,
                    "s": [620.5, 234, 0],
                    "to": [-10, 0, 0],
                    "ti": [6.667, 0, 0]
                }, {
                    "i": {"x": 0.833, "y": 0.833},
                    "o": {"x": 0.167, "y": 0.167},
                    "t": 76,
                    "s": [560.5, 234, 0],
                    "to": [-6.667, 0, 0],
                    "ti": [-3.333, 0, 0]
                }, {"t": 91, "s": [580.5, 234, 0]}],
                "ix": 2
            },
            "a": {"a": 0, "k": [30, 38.5, 0], "ix": 1},
            "s": {"a": 0, "k": [250, 250, 100], "ix": 6}
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0], [-3.473, 3.244], [-5.744, 0], [0, -13.71], [0, 0], [0, 0], [0, 0], [4.863, 4.912], [7.966, 0], [3.798, -1.714], [2.036, -2.684], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, -5.743], [3.475, -3.242], [11.3, 0], [0, 0], [0, 0], [0, 0], [0, -8.428], [-4.866, -4.908], [-4.169, 0], [-3.8, 1.715], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[-17.925, 37.865], [-17.925, -9.101], [-12.715, -22.58], [1.112, -27.443], [18.064, -6.878], [18.064, 37.865], [29.32, 37.865], [29.32, -10.492], [22.026, -30.501], [2.779, -37.865], [-9.171, -35.295], [-17.925, -28.695], [-18.204, -28.695], [-18.204, -35.782], [-29.32, -35.782], [-29.32, 37.865]],
                        "c": true
                    },
                    "ix": 2
                },
                "nm": "Pfad 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fläche 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [29.57, 38.116], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transformieren"
            }],
            "nm": "Gruppe 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }],
        "ip": 76,
        "op": 140,
        "st": 76,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 4,
        "ty": 4,
        "nm": "a/fbr Konturen",
        "sr": 1,
        "ks": {
            "o": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833], "y": [0.833]},
                    "o": {"x": [0.167], "y": [0.167]},
                    "t": 67,
                    "s": [0]
                }, {"t": 116, "s": [100]}],
                "ix": 11
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {
                "a": 1,
                "k": [{
                    "i": {"x": 0.833, "y": 0.833},
                    "o": {"x": 0.167, "y": 0.167},
                    "t": 67,
                    "s": [347.5, 236.5, 0],
                    "to": [0.824, 0, 0],
                    "ti": [-1.743, 0, 0]
                }, {
                    "i": {"x": 0.833, "y": 0.833},
                    "o": {"x": 0.167, "y": 0.167},
                    "t": 70,
                    "s": [351.501, 236.5, 0],
                    "to": [5.311, 0, 0],
                    "ti": [-2.51, 0, 0]
                }, {"t": 82, "s": [367.5, 236.5, 0]}],
                "ix": 2
            },
            "a": {"a": 0, "k": [29.5, 39.5, 0], "ix": 1},
            "s": {"a": 0, "k": [250, 250, 100], "ix": 6}
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr", "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0], [4.955, 4.215], [8.522, 0], [4.815, -4.03], [1.204, -6.484], [0, 0], [-9.82, 0], [-2.55, -2.085], [0, -3.148], [0.694, -1.111], [0.925, -0.742], [2.131, -0.784], [1.619, -0.462], [3.337, -0.832], [3.754, -3.472], [0, -6.116], [-4.218, -3.981], [-8.153, 0], [-3.89, 2.177], [-1.388, 2.411], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, -8.987], [-4.955, -4.215], [-7.69, 0], [-4.819, 4.031], [0, 0], [2.13, -9.726], [5.188, 0], [2.546, 2.084], [0, 1.39], [-0.694, 1.112], [-0.927, 0.742], [-2.132, 0.788], [-1.621, 0.465], [-8.985, 2.318], [-3.751, 3.475], [0, 6.022], [4.213, 3.983], [5.836, 0], [3.892, -2.174], [0, 0], [0, 0], [0, 0]],
                        "v": [[28.834, 36.823], [28.834, -12.783], [21.399, -32.585], [1.181, -38.908], [-17.577, -32.863], [-26.611, -17.091], [-16.327, -14.173], [1.598, -28.764], [13.202, -25.637], [17.021, -17.786], [15.98, -14.035], [13.549, -11.254], [8.962, -8.964], [3.335, -7.087], [-4.1, -5.142], [-23.207, 3.543], [-28.834, 17.926], [-22.51, 32.932], [-3.959, 38.908], [10.63, 35.641], [18.55, 28.763], [18.829, 28.763], [18.829, 36.823]],
                        "c": true
                    },
                    "ix": 2
                },
                "nm": "Pfad 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ind": 1,
                "ty": "sh",
                "ix": 2,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [3.613, -3.009], [6.67, 0], [0, 7.69], [-2.271, 2.038], [-6.3, 1.762], [-1.297, 2.689], [0, 0]],
                        "o": [[0, 4.355], [-3.611, 3.012], [-9.727, 0], [0, -3.242], [2.269, -2.038], [11.302, -3.147], [0, 0], [0, 0]],
                        "v": [[17.718, 13.201], [12.297, 24.249], [-3.126, 28.763], [-17.718, 17.23], [-14.313, 9.31], [-1.459, 3.612], [17.438, -5.142], [17.718, -5.142]],
                        "c": true
                    },
                    "ix": 2
                },
                "nm": "Pfad 2",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "mm",
                "mm": 1,
                "nm": "Pfade zusammenführen 1",
                "mn": "ADBE Vector Filter - Merge",
                "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fläche 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [29.084, 39.158], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transformieren"
            }], "nm": "Gruppe 1", "np": 4, "cix": 2, "bm": 0, "ix": 1, "mn": "ADBE Vector Group", "hd": false
        }],
        "ip": 67,
        "op": 140,
        "st": 67,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 5,
        "ty": 4,
        "nm": "r/fbr Konturen",
        "sr": 1,
        "ks": {
            "o": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833], "y": [0.833]},
                    "o": {"x": [0.167], "y": [0.167]},
                    "t": 58,
                    "s": [0]
                }, {"t": 107, "s": [100]}],
                "ix": 11
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {
                "s": true,
                "x": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.638], "y": [0.711]},
                        "o": {"x": [0.015], "y": [0.144]},
                        "t": 58,
                        "s": [195]
                    }, {
                        "i": {"x": [0.91], "y": [1.003]},
                        "o": {"x": [0.522], "y": [0.606]},
                        "t": 70,
                        "s": [212.063]
                    }, {"t": 73, "s": [215]}],
                    "ix": 3
                },
                "y": {
                    "a": 1,
                    "k": [{"i": {"x": [0.983], "y": [1]}, "o": {"x": [0.017], "y": [0]}, "t": 54, "s": [235]}, {
                        "t": 68,
                        "s": [235]
                    }],
                    "ix": 4
                }
            },
            "a": {"a": 0, "k": [17, 38, 0], "ix": 1},
            "s": {"a": 0, "k": [250, 250, 100], "ix": 6}
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0], [-2.133, 3.104], [-4.724, 0], [-1.39, -0.743], [0, 0], [0, 0], [1.065, 0.23], [1.112, 0], [2.871, -1.575], [1.483, -2.962], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, -6.855], [2.13, -3.104], [2.5, 0], [0, 0], [0, 0], [-0.556, -0.184], [-1.065, -0.231], [-2.967, 0], [-2.873, 1.576], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[-5.003, 37.449], [-5.003, -6.322], [-1.807, -21.259], [8.476, -25.915], [14.312, -24.801], [16.398, -36.059], [15.701, -36.477], [13.269, -37.099], [10.006, -37.449], [1.251, -35.086], [-5.282, -28.277], [-5.559, -28.277], [-5.559, -36.198], [-16.398, -36.198], [-16.398, 37.449]],
                        "c": true
                    },
                    "ix": 2
                },
                "nm": "Pfad 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fläche 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [16.647, 37.7], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transformieren"
            }],
            "nm": "Gruppe 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }],
        "ip": 58,
        "op": 140,
        "st": 58,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 6,
        "ty": 4,
        "nm": "f/fbr Konturen",
        "sr": 1,
        "ks": {
            "o": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833], "y": [0.833]},
                    "o": {"x": [0.167], "y": [0.167]},
                    "t": 49,
                    "s": [0]
                }, {"t": 98, "s": [100]}],
                "ix": 11
            },
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {
                "s": true,
                "x": {
                    "a": 1,
                    "k": [{
                        "i": {"x": [0.287], "y": [1]},
                        "o": {"x": [0.167], "y": [0.167]},
                        "t": 49,
                        "s": [51.5]
                    }, {"i": {"x": [0.833], "y": [1]}, "o": {"x": [0], "y": [0]}, "t": 64, "s": [71.5]}, {
                        "t": 70,
                        "s": [71.5]
                    }],
                    "ix": 3
                },
                "y": {"a": 0, "k": 201, "ix": 4}
            },
            "a": {"a": 0, "k": [20.5, 51, 0], "ix": 1},
            "s": {"a": 0, "k": [250, 250, 100], "ix": 6}
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-0.973, 1.113], [-2.871, 0], [0, 0], [0, 0], [0, 0], [2.778, -2.407], [0, -6.205], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, -3.52], [0.973, -1.111], [0, 0], [0, 0], [0, 0], [-5.19, 0], [-2.781, 2.409], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[1.738, 50.719], [1.738, -12.784], [19.801, -12.784], [19.801, -22.928], [1.6, -22.928], [1.6, -31.96], [3.058, -38.909], [8.824, -40.576], [18.552, -40.576], [18.552, -50.719], [6.741, -50.719], [-5.21, -47.106], [-9.379, -34.184], [-9.379, -22.928], [-19.801, -22.928], [-19.801, -12.784], [-9.517, -12.784], [-9.517, 50.719]],
                        "c": true
                    },
                    "ix": 2
                },
                "nm": "Pfad 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "fl",
                "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 4},
                "o": {"a": 0, "k": 100, "ix": 5},
                "r": 1,
                "bm": 0,
                "nm": "Fläche 1",
                "mn": "ADBE Vector Graphic - Fill",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [20.05, 50.969], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transformieren"
            }],
            "nm": "Gruppe 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }],
        "ip": 49,
        "op": 140,
        "st": 46,
        "bm": 0
    }, {
        "ddd": 0,
        "ind": 7,
        "ty": 4,
        "nm": "F/fbr Konturen",
        "sr": 1,
        "ks": {
            "o": {"a": 0, "k": 100, "ix": 11},
            "r": {"a": 0, "k": 0, "ix": 10},
            "p": {"a": 0, "k": [1173.5, 202.5, 0], "ix": 2},
            "a": {"a": 0, "k": [57, 77, 0], "ix": 1},
            "s": {"a": 0, "k": [250, 250, 100], "ix": 6}
        },
        "ao": 0,
        "shapes": [{
            "ty": "gr",
            "it": [{
                "ind": 0,
                "ty": "sh",
                "ix": 1,
                "ks": {
                    "a": 0,
                    "k": {
                        "i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                        "v": [[55.272, -75.494], [-55.272, -75.494], [-55.272, 75.494], [-6.235, 75.494], [-6.235, 26.009], [42.805, 26.009], [42.805, -23.473], [-9.986, -23.473]],
                        "c": false
                    },
                    "ix": 2
                },
                "nm": "Pfad 1",
                "mn": "ADBE Vector Shape - Group",
                "hd": false
            }, {
                "ty": "st",
                "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
                "o": {"a": 0, "k": 100, "ix": 4},
                "w": {"a": 0, "k": 1, "ix": 5},
                "lc": 1,
                "lj": 1,
                "ml": 6,
                "bm": 0,
                "nm": "Kontur 1",
                "mn": "ADBE Vector Graphic - Stroke",
                "hd": false
            }, {
                "ty": "tr",
                "p": {"a": 0, "k": [56.772, 76.994], "ix": 2},
                "a": {"a": 0, "k": [0, 0], "ix": 1},
                "s": {"a": 0, "k": [100, 100], "ix": 3},
                "r": {"a": 0, "k": 0, "ix": 6},
                "o": {"a": 0, "k": 100, "ix": 7},
                "sk": {"a": 0, "k": 0, "ix": 4},
                "sa": {"a": 0, "k": 0, "ix": 5},
                "nm": "Transformieren"
            }],
            "nm": "Gruppe 1",
            "np": 2,
            "cix": 2,
            "bm": 0,
            "ix": 1,
            "mn": "ADBE Vector Group",
            "hd": false
        }, {
            "ty": "tm",
            "s": {"a": 0, "k": 0, "ix": 1},
            "e": {
                "a": 1,
                "k": [{
                    "i": {"x": [0.833], "y": [0.833]},
                    "o": {"x": [0.167], "y": [0.167]},
                    "t": 52,
                    "s": [0]
                }, {"t": 88, "s": [100]}],
                "ix": 2
            },
            "o": {"a": 0, "k": 0, "ix": 3},
            "m": 1,
            "ix": 2,
            "nm": "Pfade trimmen 1",
            "mn": "ADBE Vector Filter - Trim",
            "hd": false
        }],
        "ip": 52,
        "op": 140,
        "st": 52,
        "bm": 0
    }],
    "markers": []
};

export default dataLogo;
