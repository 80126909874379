const cookieBanner = document.querySelector('.cookie-banner');
const acceptBtn = document.querySelector('.cookie-banner__button');

/**
 * When the "accept" button is clicked, we hide the banner directly (no page reload required) and set a cookie.
 * On subsequent reloads, the banner will not be rendered anymore by django because of the cookie.
 */
acceptBtn && acceptBtn.addEventListener('click', () => {
  if (cookieBanner && !cookieBanner.classList.contains('cookie-banner--hidden')) {
    cookieBanner.classList.add('cookie-banner--hidden');
  }
  document.cookie = 'cookie_consent=true; expires=\'Thu, 31 Dec 2099 23:59:59 UTC\'; path=/';
});
