const optOutBtn = document.querySelector('.google-analytics-opt-out__button');
const gaProperty = window.GA_PROPERTY; // We write this to the window through django in the template.
const gaDisableStr = `ga-disable-${gaProperty}`; // This is the string required by google

/**
 * The user can opt out of analytics by clicking a button usually embedded in the privacy page.
 * We then set a variable to the window whioch the google script interprets, see
 * https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out.
 * In base.html we check this cookie and set the variable again.
 */
optOutBtn && optOutBtn.addEventListener('click', () => {
  console.log(`Disabled tracking for Google Analytics property ${gaProperty}.`);
  document.cookie = `${gaDisableStr}=true; expires=\'Thu, 31 Dec 2099 23:59:59 UTC\'; path=/`;
  window[gaDisableStr] = true;
  alert('Tracking was successfully disabled.');
});
