const address = window.ADDRESS;

window.initMap = () => {
  if (address) {
    const styles = [
      {
        featureType: "all",
        elementType: "all",
        stylers: [
          {saturation: -100}
        ]
      }
    ];
    const mapType = new google.maps.StyledMapType(styles, {name: "Grayscale"});

    const icon = {
      url: '/static/pin.png', // url
      scaledSize: new google.maps.Size(50, 50), // scaled size
    };

    let mapOptions = {
      zoom: 14,
      center: null
    };

    geocoder = new google.maps.Geocoder();
    geocoder.geocode({'address': address}, function (results, status) {
      if (status === 'OK') {
        mapOptions.center = results[0].geometry.location;
        map = new google.maps.Map(document.getElementById('google-map-module'), mapOptions);
        map.mapTypes.set('grayscale', mapType);
        map.setMapTypeId('grayscale');
        map.setCenter(results[0].geometry.location);
        let marker = new google.maps.Marker({
          map: map,
          position: results[0].geometry.location,
          icon: icon,
        });
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    });
  }
};
