import Swiper from 'swiper';

const captionElements = document.querySelectorAll('.gallery__caption');
const swiper = new Swiper('.gallery .swiper-container', {
    autoHeight: false,
    loop: true,
    autoplay: {
        delay: 10000,
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
    }
});

swiper.on('slideChange', function () {
    const realIndex = this.realIndex;

    Array.prototype.forEach.call(captionElements, (el, index) => {
        (index === realIndex) ? el.style.opacity = 1 : el.style.opacity = 0;
    });
});

