import Swiper from 'swiper';

const swiper = new Swiper('.field-grid .swiper-container', {
    autoHeight: false,
    loop: true,
    autoplay: {
        delay: 10000,
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
    }
});
