import Swiper from 'swiper';

let mql = window.matchMedia("(max-width:1000px)");

const querySelector = '.seminar-list--teaser .swiper-container';

const options = {
    loop: false,
    slidesPerView: 1,
    slidesPerColumn: 2,
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
    },
};

let swiper = new Swiper('.seminar-list--teaser .swiper-container', options);

if (document.querySelector(querySelector)) {
    mql.addListener(function (mql) {
        if (mql.matches) {
            swiper.attachEvents();
        } else {
            console.log('detach');
            swiper.detachEvents();
        }
    });

    if (!mql.matches) {
        console.log('detach');
        swiper.detachEvents();
    }
}

