import Swiper from 'swiper';

(function (Swiper) {

    const querySeletor = '.news-swiper';

    const breakpoint = window.matchMedia("(max-width:54em)");

    let swiper;

    const breakpointChecker = function () {
        // mobile view --> enable swiper
        if (breakpoint.matches) {
            return enableSwiper();
        }
        // desktop view --> destroy swiper
        else {
            swiper && swiper.destroy(true, true);
        }
    };

    const enableSwiper = function () {
        swiper = new Swiper(querySeletor, {
            loop: true,
            autoHeight: true,
            autoplay: {
                delay: 5000 // 5s
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            grabCursor: true
        });
    };

    if (document.querySelector(querySeletor)) {
        // keep an eye on viewport size changes
        breakpoint.addListener(breakpointChecker);
        // kick start
        breakpointChecker();
    }

})(Swiper);
