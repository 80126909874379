const headerMenuBtn = document.querySelector('.menu-button__button');
const navCloseBtns = document.querySelectorAll('.nav__menu-btn, .nav__menu-close-btn');
const navEl = document.querySelector('.nav');
const navInnerWrap = document.querySelector('.nav__inner-wrap');

headerMenuBtn.addEventListener('click', () => {
  if (navEl && !navEl.classList.contains('nav--open')) {
    headerMenuBtn.classList.remove('menu-button__button--closed');
    headerMenuBtn.classList.add('menu-button__button--open');
    navEl.classList.add('nav--open');
    navEl.style.height = '100%';
    document.addEventListener('click', hideNavOnOutsideClick);
  } else if (navEl && navEl.classList.contains('nav--open')) {
    hideNav();
  }
});

navEl.addEventListener("transitionend", () => {
  if (navEl && !navEl.classList.contains('nav--open')) {
    navEl.style.height = '0';
  }
});

const hideNavOnOutsideClick = (evt) => {
  if (!navInnerWrap.contains(evt.target) && headerMenuBtn !== evt.target) {
    hideNav();
  }
};

const hideNav = () => {
  navEl.classList.remove('nav--open');
  document.removeEventListener('click', hideNavOnOutsideClick);
  headerMenuBtn.classList.remove('menu-button__button--open');
  headerMenuBtn.classList.add('menu-button__button--closed');
};
